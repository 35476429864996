<template>
  <div class="shopdetail">
    <div class="topcont">
      <div class="products">
        <div class="pic">
          <van-swipe class="my-swipe" :autoplay="3000" :loop="false" indicator-color="white">
            <van-swipe-item v-for="(item, index) in infoData.banner_imgs" :key="index">
              <viewer :images="item">
                <van-image width="100%" height="100%" lazy-load :src="item" @click="clickBigShow(item)" />
              </viewer>
            </van-swipe-item>
          </van-swipe>
          <!-- <img :src="infoData.banner_imgs" alt=""> -->
        </div>
        <div class="text">
          <div class="price">￥{{ infoData.price }}<span>￥{{ infoData.original_price }}</span></div>
          <div class="name">{{ infoData.title }}</div>
        </div>
      </div>
      <div class="contant" v-html="infoData.content"></div>
    </div>
    <div class="btns">
      <div class="icon" @click="linkClick"><img src="@/assets/img/de_icon1.png" alt=""></div>
      <button type="button" @click="toOrderList">我的订单</button>
      <button type="button" @click="showBuyPup = true">立即支付</button>
    </div>

    <div class="flowbutton" @click="flowClick"><img src="@/assets/img/fo_icon.png" alt=""></div>

    <van-popup v-model:show="showBuyPup" position="bottom" style="height:80vh" round>
      <shopbuybox :areaList="infoData.region" :shareId="share_id" :skudata="infoData.sku_list"
        :freight="infoData.default_freight" :goods_id="goods_id" />
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import { useRouter, useRoute } from "vue-router";
import { Toast } from "vant";
import API from "@/axios";
import tokenApi from "@/axios/tokenApi";
import shopbuybox from "@/views/shopdetail/shopbuybox"
import wx from 'jweixin-js';


const router = useRouter();
const route = useRoute();

const infoData = ref()
const showBuyPup = ref(false)
const share_id = ref("")
const goods_id = ref('')
const userToken = ref("")
const appId = ref("")
const code = ref("")

//微信公众号授权发起地址
const scope_url = ref('');

const reload = ref(false)

// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  'checkJsApi',
];

const goodsDetail = () => {
  // 提交按钮
  tokenApi({
    url: "api/goodsDetail",
    method: "post",
    data: {
      share_id: share_id.value,
      goods_id: goods_id.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      infoData.value = res.data.data
      goods_id.value = res.data.data.goods_id;
      loadWxShare()
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

const linkClick = () => {
  window.location.href = "tel://" + infoData.value.bd_tel;
}

// 关注
const flowClick = () => {
  window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5MTg1NTc4MQ==#wechat_redirect"
}

const toOrderList = () => {
  router.push({
    name: "DoubleElevenOrder"
  });
}


// 微信授权分享登入 开始

//获取微信配置并初始化微信jssdk
function getWxConfig(getCode = false) {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    reload.value = true
    if (res.data.status == 200) {
      appId.value = res.data.data.appId;
      scope_url.value = res.data.data.scope_url;

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });
      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)

        // Toast.fail({
        //   message: '微信JSSDK信息验证失败！'
        // });

        if (reload.value && share_id.value !== "") {
          location.reload();
          reload.value = false
        }
      });

      //是否需要授权登录
      if (getCode) {
        getwxCode();
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//微信授权
function getwxCode() {
  let userToken = window.localStorage.getItem('userToken');
  const hrefUrl = window.location.href.split("#")[0]
  if (!userToken) {
    window.location.href = scope_url.value + '?back=' + encodeURIComponent(hrefUrl);
  }
}
//微信登录-》活动详情
function sendCode() {
  API({
    url: "/web/visitorLogin",
    method: "post",
    data: {
      code: code.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res)
      userToken.value = res.data.data.userToken
      window.localStorage.setItem('userToken', userToken.value);
      goodsDetail()
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}




//加载分享组件
function loadWxShare() {
  wx.ready(function () {
    let shareTitle = infoData.value.share_title;
    let shareDesc = infoData.value.share_desc;
    let shareLink = infoData.value.share_url;
    let shareImg = infoData.value.share_img;
    //1.4以上用这个分享给朋友
    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    //1.4以上用这个分享到朋友圈
    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })
  });
}

// 微信授权分享登入 开始

onMounted(() => {
  if (route.query.share_id) {
    share_id.value = route.query.share_id
  }

  if (route.query.goods_id) {
    goods_id.value = route.query.goods_id
  }

  goodsDetail()

  // 微信授权分享登入 开始

  if (route.query.code) {
    code.value = route.query.code
  } else {
    // code.value = "081m8EFa1VCVbH0mMvHa16rRjX3m8EFt"
  }

  if (userToken.value) {
    // console.log("有userToken",userToken.value)
    getWxConfig();
    goodsDetail()
  } else {
    // console.log("无userToken")

    if (code.value) {
      //授权回调后,登录并获取活动详情
      // console.log("有code",code.value)
      getWxConfig();
      sendCode()
    } else {
      //无token无code则开始授权
      getWxConfig(true);
    }
  }

  // 微信授权分享登入 开始
})


</script>

<style lang="less" scoped>
.shopdetail {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.topcont{
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
}

.contant {
  width: 100%;
  p {
    img {
      max-width: 100% !important;
      display: block !important;
    }
  }
}

.btns {
  display: flex;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #dfdfdf;

  .icon {
    width: 40px;
    margin: 0 35px 0 16px;

    img {
      width: 100%;
      display: block;
    }
  }

  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
  }
}

.flowbutton {
  position: fixed;
  right: 30px;
  bottom: 200px;
  background: #fff;
  width: 110px;
  height: 110px;
  line-height: 130px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 20px #dfdfdf;
  color: #333;
  font-size: 30px;
  z-index: 30;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.products {
  overflow: hidden;
  margin-bottom: 20px;

  .pic {
    width: 100%;
    box-sizing: border-box;

    img {
      width: 100%;
      display: block;
    }
  }

  .text {
    background: #fff;
    padding: 20px;
    color: #333;
    font-family: "Regular";

    .price {
      color: red;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;

      span {
        font-size: 32px;
        text-decoration: line-through;
      }
    }

    .name {
      font-size: 32px;
      font-weight: 600;
      color: #333;
    }
  }
}
</style>